import { TActionResult } from 'api/Api/BaseApi/types';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

import { NextPage } from 'next';

import ErrorContent from 'components/common/contents/ErrorContent';
import { useTranslation } from 'react-i18next';

const Error: NextPage = () => {
  const router = useRouter();
  const { t } = useTranslation();

  const errorDescription = useMemo(() => {
    try {
      if (router.query && router.query.description) {
        const actionResult: TActionResult<void> = JSON.parse(router.query.description as string);
        return actionResult.resolvedMessage;
      }
    } catch (_) {
      return t('serverGenericErrorDescription');
    }
    return t('serverGenericErrorDescription');
  }, [router.query, t]);

  return <ErrorContent errorDescription={errorDescription} />;
};

export default Error;
